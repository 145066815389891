import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import { useLanguage } from '../contexts/LanguageContext';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const ScrollableFormWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 20px; // Add some space above the submit button
`;

const SubmitButtonContainer = styled.div`
  padding: 20px;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.8);
`;

const SubmitButton = styled.button`
  background-color: #47ABDB;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0px;
  margin-top: 20px; // Add some space above the button

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 0.9rem;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
  margin-top: 3px;
`;

const CheckboxLabel = styled.label`
  font-size: 0.9rem;
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`;

const FormColumn = styled.div`
  flex: 1 1 200px;
  min-width: 0;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 0.9rem;
  background-color: rgba(255, 255, 255, 0.8);
`;

const RegistrationForm = ({ onSubmitSuccess, eventInfo, registrationSuccess }) => {
  const intl = useIntl();
  const { language } = useLanguage();
  const [staticFormData, setStaticFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    jobTitle: '',
    specialization: '',
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    eventId: eventInfo?.id ? Number(eventInfo.id) : null,
  });
  const [dynamicFormData, setDynamicFormData] = useState({});
  const [dynamicFields, setDynamicFields] = useState([]);
  const [consentGiven, setConsentGiven] = useState(false);
  const [chamberOfCommerceTicket, setChamberOfCommerceTicket] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    fetchDynamicFields();
  }, []);

  const fetchDynamicFields = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cms/form-item/public/get-all-active`);
      setDynamicFields(response.data);
      initializeDynamicFormData(response.data);
    } catch (error) {
      console.error('Error fetching dynamic form fields:', error);
      setError(intl.formatMessage({ id: 'form.fetchError', defaultMessage: 'Failed to load additional form fields. Please try again.' }));
    }
  };

  const initializeDynamicFormData = (fields) => {
    const dynamicData = {};
    fields.forEach(field => {
      dynamicData[field.label] = '';
    });
    setDynamicFormData(dynamicData);
  };

  const handleStaticChange = (e) => {
    const { name, value } = e.target;
    setStaticFormData({ ...staticFormData, [name]: value });
  };

  const handleDynamicChange = (e) => {
    setDynamicFormData({ ...dynamicFormData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setConsentGiven(e.target.checked);
  };

  const handleChamberOfCommerceChange = (e) => {
    setChamberOfCommerceTicket(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitted(false);
    
    if (!consentGiven) {
      setError(intl.formatMessage({ id: 'consent.required', defaultMessage: 'You must agree to the data processing to register.' }));
      return;
    }

    const formDataToSend = new FormData();
    Object.keys(staticFormData).forEach(key => {
      let value = staticFormData[key];
      if (key === 'title' && language === 'de') {
        // Map German title to English only when sending data
        value = titleMapping[value] || value;
      }
      if (key === 'company' && chamberOfCommerceTicket) {
        value = `WKO-${value}`;
      }
      formDataToSend.append(key, value);
    });
    formDataToSend.append('additionalData', JSON.stringify(dynamicFormData));
    console.log("Sending registration data:", Object.fromEntries(formDataToSend));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/cms/users/public/register`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setIsSubmitted(true);
      onSubmitSuccess();
      // Clear form data after successful submission
      setStaticFormData({
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        jobTitle: '',
        specialization: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        eventId: eventInfo?.id ? Number(eventInfo.id) : null,
      });
      setDynamicFormData({});
      setConsentGiven(false);
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response && error.response.data && error.response.data.includes("User is already registered for this event")) {
        setError(intl.formatMessage({ id: 'registration.alreadyRegistered', defaultMessage: 'You are already registered for this event.' }));
      } else {
        setError(intl.formatMessage({ id: 'registration.error', defaultMessage: 'Registration failed. Please try again.' }));
      }
    }
  };

  const renderStaticField = (name, label, type = "text", required = true, options = null) => {
    return (
      <FormGroup key={name}>
        <Label htmlFor={name}>
          <FormattedMessage id={`form.${name}`} defaultMessage={label} />
        </Label>
        {type === "select" ? (
          <Select
            id={name}
            name={name}
            value={staticFormData[name]}
            onChange={handleStaticChange}
            required={required}
          >
            <option value="">{intl.formatMessage({ id: 'form.select', defaultMessage: 'Select...' })}</option>
            {options && options[language] && options[language].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        ) : (
          <Input
            type={type}
            id={name}
            name={name}
            value={staticFormData[name]}
            onChange={handleStaticChange}
            required={required}
            maxLength={100}
          />
        )}
      </FormGroup>
    );
  };

  const titleOptions = {
    en: ['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.', 'No title'],
    de: ['Herr', 'Frau', 'Dr.', 'Prof.', 'keine Angabe']
  };

  const titleMapping = {
    'Herr': 'Mr.',
    'Frau': 'Ms.',
    'Dr.': 'Dr.',
    'Prof.': 'Prof.',
    'keine Angabe': 'No title'
  };

  const specializationOptions = {
    en: [
      'Non Medical',
      'Anesthesia',
      'Cardiac Surgery',
      'Cardiology',
      'General Practitioner (GP)',
      'Trauma and Orthopedics',
      'Radiology',
      'Dermatology',
      'Endocrinology',
      'Gastroenterology',
      'Hematology',
      'Immunology',
      'Nephrology',
      'Neurology',
      'Obstetrics and Gynecology (OB/GYN)',
      'Oncology',
      'Ophthalmology',
      'Otolaryngology (ENT)',
      'Pediatrics',
      'Psychiatry',
      'Pulmonology',
      'Rheumatology',
      'Surgery',
      'Urology',
      'Other'
    ],
    de: [
      'Nicht-Medizinisch',
      'Allgemeinmedizin',
      'Anästhesie',
      'Augenheilkunde',
      'Chirurgie',
      'Dermatologie',
      'Endokrinologie',
      'Gastroenterologie',
      'Geburtshilfe und Gynäkologie',
      'Hals-Nasen-Ohren-Heilkunde',
      'Hämatologie',
      'Herzchirurgie',
      'Immunologie',
      'Kardiologie',
      'Nephrologie',
      'Neurologie',
      'Onkologie',
      'Pädiatrie',
      'Psychiatrie',
      'Pneumologie',
      'Radiologie',
      'Rheumatologie',
      'Unfallchirurgie und Orthopädie',
      'Urologie',
      'Andere'
    ]
  };


  if (registrationSuccess) {
    return (
      <SuccessMessage>
        <FormattedMessage
          id="registration.success"
          defaultMessage="Registration successful! Thank you for registering."
        />
      </SuccessMessage>
    );
  }

  return (
    <FormContainer>
      <ScrollableFormWrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <FormRow>
            <FormColumn>
              {renderStaticField("title", "Title", "select", true, titleOptions)}
            </FormColumn>
            <FormColumn>
              {renderStaticField("firstName", "First Name")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("lastName", "Last Name")}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              {renderStaticField("email", "Email Address", "email")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("phoneNumber", "Phone Number", "tel")}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              {renderStaticField("company", "Company")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("jobTitle", "Job Title")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("specialization", "Job Title", "select", true, specializationOptions)}
            </FormColumn>
          </FormRow>
          {renderStaticField("streetAddress", "Street Address")}
          <FormRow>
            <FormColumn>
              {renderStaticField("postalCode", "Postal Code")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("city", "City")}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              {renderStaticField("state", "State/Province")}
            </FormColumn>
            <FormColumn>
              {renderStaticField("country", "Country")}
            </FormColumn>
          </FormRow>
          
          {dynamicFields.map((field) => (
            <FormGroup key={field.id}>
              <Label htmlFor={field.label}>
                {intl.locale === 'en' ? field.labelEN : field.label}
              </Label>
              <Input
                type="text"
                id={field.label}
                name={field.label}
                value={dynamicFormData[field.label] || ''}
                onChange={handleDynamicChange}
                required={field.required}
                maxLength={100}
              />
            </FormGroup>
          ))}

          <CheckboxGroup>
            <Checkbox
              type="checkbox"
              id="chamberOfCommerceTicket"
              name="chamberOfCommerceTicket"
              checked={chamberOfCommerceTicket}
              onChange={handleChamberOfCommerceChange}
            />
            <CheckboxLabel htmlFor="chamberOfCommerceTicket">
              <FormattedMessage 
                id="form.chamberOfCommerceTicket"
                defaultMessage="Ticket allocation of the Chamber of Commerce"
              />
            </CheckboxLabel>
          </CheckboxGroup>

          
          <CheckboxGroup>
            <Checkbox
              type="checkbox"
              id="consentGiven"
              name="consentGiven"
              checked={consentGiven}
              onChange={handleCheckboxChange}
              required
            />
            <CheckboxLabel htmlFor="consentGiven">
              <FormattedMessage 
                id="form.consentText"
                defaultMessage="I agree to the processing of my personal data for the purpose of event registration and communication related to this event. I understand that I can withdraw my consent at any time."
              />
            </CheckboxLabel>
          </CheckboxGroup>
          
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <SubmitButton type="submit">
            <FormattedMessage id="form.register" defaultMessage="Register" />
          </SubmitButton>
        </FormWrapper>
      </ScrollableFormWrapper>
    </FormContainer>
  );
};

export default RegistrationForm;

